export const styles = {
  global: {
    body: {
      fontFamily: `'Satoshi', Arial, sans-serif`,
      color: `var(--text-color)`,
      background: `var(--background-color)`,
      width: `100%`,
      transition: `color 1s cubic-bezier(0.72,0,0.28,1),background-color .65s cubic-bezier(0.72,0,0.28,1)`
    }
  }
};
