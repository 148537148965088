export const components = {
  Heading: {
    baseStyle: {
      fontWeight: `medium`,
      mb: 12
    },
    sizes: {
      sm: {
        fontSize: `sm`
      },
      md: {
        fontSize: `md`
      },
      lg: {
        fontSize: `lg`,
        lineHeight: `short`
      },
      xl: {
        fontSize: `xl`,
        lineHeight: `short`
      },
      '2xl': {
        fontSize: `2xl`,
        lineHeight: `short`
      },
      '3xl': {
        fontSize: `3xl`,
        lineHeight: `short`
      },
      '4xl': {
        fontSize: [`3xl`, `3xl`, `4xl`],
        lineHeight: `shorter`
      },
      '5xl': {
        fontSize: [`3xl`, `3xl`, `4xl`, `5xl`],
        lineHeight: `shorter`
      },
      '6xl': {
        fontSize: [`4xl`, `4xl`, `5xl`, `6xl`],
        lineHeight: `shorter`
      }
    }
  },
  Text: {
    baseStyle: {
      marginBottom: 8
    }
  },
  Table: {
    variants: {
      simple: {
        thead: {
          display: {
            base: `none`,
            md: `table-header-group`
          }
        },
        tbody: {
          display: {
            base: `block`,
            md: `table-row-group`
          }
        },
        tr: {
          display: {
            base: `block`,
            md: `table-row`
          },
          pb: {
            base: 4,
            md: 0
          }
        },
        th: {
          textTransform: `none`,
          fontWeight: `medium`,
          color: `var(--text-color)`,
          borderColor: `var(--text-color)`,
          px: 4,
          py: 10
        },
        td: {
          display: {
            base: `flex`,
            md: `table-cell`
          },
          alignItems: `flex-start`,
          justifyContent: `space-between`,
          whiteSpace: `normal`,
          textAlign: {
            base: `right`,
            md: `left`
          },
          color: `var(--text-color)`,
          borderColor: `var(--text-color)`,
          px: {
            base: 0,
            md: 4
          },
          py: {
            base: 5,
            md: 10
          },
          '&[data-label]': {
            _before: {
              content: `attr(data-label)`,
              display: {
                base: `block`,
                md: `none`
              },
              flex: `0 0 auto`,
              fontWeight: `medium`,
              textAlign: `left`,
              pr: 2
            }
          }
        }
      }
    }
  }
};
