export const colors = {
  black: `#0C0C0C`,
  white: `#FAFAFA`,
  grey: {
    1: `#ADA4A4`,
    2: `#CDCACA`,
    3: `#545454`,
    4: `#414141`
  }
};
