import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { theme } from './src/styles/theme';

export const wrapPageElement = ({ element }) => (
  <ChakraProvider theme={theme}>
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </ChakraProvider>
);
